// src/hooks/useDashboardCheck.js
import { useLocation } from 'react-router-dom';

const useDashboardCheck = () => {
    const location = useLocation();
    const dashboardRoutes = ['/user-dashboard', '/vendor-dashboard', '/admin-dashboard', '/event-coordinator-dashboard'];
    return dashboardRoutes.includes(location.pathname);
};

export default useDashboardCheck;
