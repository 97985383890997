import React from 'react'

const Vendor = () => {
  return (
<div style={{display:'flex',justifyContent:'center',marginBottom:'500px',marginTop:'200px'}}>
  <h1>Vendor Dashboard </h1>
</div>
  )
}

export default Vendor