import { configureStore } from '@reduxjs/toolkit'
import RegisterReducer from './Slice/RegisterSlice'
import loginReducer from './Slice/loginSlice'




export default configureStore({
  reducer: {
     RegisterR:RegisterReducer,
     loginR:loginReducer
    
  },
})