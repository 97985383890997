import React from "react";

import "./PopularLocations.css";
const PopularLocations = () => {
  return (
    <div>
      <section id="PopularLocations">
        <div className="container" data-aos="fade-up">
          <div className="row align-items-center">
            <div className="centered-container">
              <h2
                className="section-heading"
                data-aos="zoom-in-down"
                data-aos-delay="100"
                data-aos-duration="900"
              >
                Perfect Places
              </h2>
              <p
                className="section-para text-border"
                data-aos="zoom-in-up"
                data-aos-delay="150"
                data-aos-duration="900"
              >
                "Explore the most enchanting locations for your perfect day."
              </p>
            </div>
          </div>
          <div className="row align-items-center">
            <div
              className="col"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <div className="card">
                <div className="card-img">
                  <img
                    src="assets/Images/Wedding-Venue.jpeg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <a href="#" className="stretched-link">
                    Kochi
                  </a>
                </h3>
                <p>
                  Cumque eos in qui numquam. Aut aspernatur perferendis sed
                  atque quia voluptas quisquam repellendus temporibus
                  itaqueofficiis odit
                </p>
              </div>
            </div>
            <div
              className="col"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="card">
                <div className="card-img">
                  <img
                    src="assets/Images/Wedding-Venue.jpeg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <a href="#" className="stretched-link">
                    Udaipur
                  </a>
                </h3>
                <p>
                  Asperiores provident dolor accusamus pariatur dolore nam id
                  audantium ut et iure incidunt molestiae dolor ipsam ducimus
                  occaecati nisi
                </p>
              </div>
            </div>
            <div
              className="col"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="card">
                <div className="card-img">
                  <img
                    src="assets/Images/Wedding-Venue.jpeg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <a href="#" className="stretched-link">
                    channai
                  </a>
                </h3>
                <p>
                  Dicta quam similique quia architecto eos nisi aut ratione aut
                  ipsum reiciendis sit doloremque oluptatem aut et molestiae ut
                  et nihil
                </p>
              </div>
            </div>
            <div
              className="col"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <div className="card">
                <div className="card-img">
                  <img
                    src="assets/Images/Wedding-Venue.jpeg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <a href="#" className="stretched-link">
                    Kolkata
                  </a>
                </h3>
                <p>
                  Dicta quam similique quia architecto eos nisi aut ratione aut
                  ipsum reiciendis sit doloremque oluptatem aut et molestiae ut
                  et nihil
                </p>
              </div>
            </div>
            <div
              className="col"
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <div className="card">
                <div className="card-img">
                  <img
                    src="assets/Images/Wedding-Venue.jpeg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <a href="#" className="stretched-link">
                    Nagpur
                  </a>
                </h3>
                <p>
                  Illo consequuntur quisquam delectus praesentium modi
                  dignissimos facere vel cum onsequuntur maiores beatae
                  consequatur magni voluptates
                </p>
              </div>
            </div>
            <div
              className="col"
              data-aos="fade-up"
              data-aos-delay="600"
            >
              <div className="card">
                <div className="card-img">
                  <img
                    src="assets/Images/Wedding-Venue.jpeg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <a href="#" className="stretched-link">
                    Agra
                  </a>
                </h3>
                <p>
                  Quas assumenda non occaecati molestiae. In aut earum sed natus
                  eatae in vero. Ab modi quisquam aut nostrum unde et qui est
                  non quo nulla
                </p>
              </div>
            </div>
            <div
              className="col"
              data-aos="fade-up"
              data-aos-delay="600"
            >
              <div className="card">
                <div className="card-img">
                  <img
                    src="assets/Images/Wedding-Venue.jpeg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <a href="#" className="stretched-link">
                    Mumbai
                  </a>
                </h3>
                <p>
                  Quas assumenda non occaecati molestiae. In aut earum sed natus
                  eatae in vero. Ab modi quisquam aut nostrum unde et qui est
                  non quo nulla
                </p>
              </div>
            </div>
            <div
              className="col"
              data-aos="fade-up"
              data-aos-delay="600"
            >
              <div className="card">
                <div className="card-img">
                  <img
                    src="assets/Images/Wedding-Venue.jpeg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <a href="#" className="stretched-link">
                    Jaipur
                  </a>
                </h3>
                <p>
                  Quas assumenda non occaecati molestiae. In aut earum sed natus
                  eatae in vero. Ab modi quisquam aut nostrum unde et qui est
                  non quo nulla
                </p>
              </div>
            </div>
            <div
              className="col"
              data-aos="fade-up"
              data-aos-delay="600"
            >
              <div className="card">
                <div className="card-img">
                  <img
                    src="assets/Images/Wedding-Venue.jpeg"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <h3>
                  <a href="#" className="stretched-link">
                    Pune
                  </a>
                </h3>
                <p>
                  Quas assumenda non occaecati molestiae. In aut earum sed natus
                  eatae in vero. Ab modi quisquam aut nostrum unde et qui est
                  non quo nulla
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PopularLocations;
