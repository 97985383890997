import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Login.css";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
const { Login } = require("../../../context/Slice/loginSlice");

const UserLogin = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const Role = useSelector((state) => state.loginR.role);

  const [Input, setInput] = useState({
    email: "",
    password: "",
  });

  const [loginImgUrl, setLoginImgUrl] = useState("");
  
  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await axios.get(
          "https://api.unsplash.com/search/photos",
          {
            params: {
              page: 1,
              query: "wedding couple",
              w: 500,
              h: 500,
              fit: "crop",
              client_id: "m3C0C_cIUxndzUt3Oti4MCAWQ3WU_RFiZQyzBtMfuNU",
            },
          }
        );
        console.log("Unsplash API Response:", response);
        const randomIndex = Math.floor(
          Math.random() * response.data.results.length
        );
        let imageUrl = response.data.results[randomIndex].urls.regular;

        console.log("Image URL:", imageUrl);
        setLoginImgUrl(imageUrl);
      } catch (error) {
        console.error("Error fetching image from Unsplash:", error);
      }
    };

    fetchImage();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("Role before dispatch:", Role);
    const action = await dispatch(Login(Input));
    console.log("action:", action);
    const role = action.payload;
    console.log("Role after dispatch:", role);
    if (role === "USER") {
      navigate("/user-dashboard");
    } else if (role === "VENDOR") {
      console.log("vendor-dashboard");
      navigate("/vendor-dashboard");
    } else if (role === "ADMIN") {
      navigate("/admin-dashboard");
    } else if (role === "EVENT-COORDINATOR") {
      navigate("/event-coordinator-dashboard");
    }
  };

  const OnRegister = (event) => {
    event.preventDefault();
    navigate("/user-registration");
  };

  const OnVendor = (event) => {
    event.preventDefault();
    navigate("/vendor-registration");
  };

  return (
    <div>
      <section id="user-login">
        <div className="container">
          <div className="row">
            <div className="img-wrapper col-sm-12 col-md-6 col-lg-6">
              <img
                src={loginImgUrl}
                alt="login form"
                className="img-fluid"
                style={{
                  borderRadius: "1rem 0 0 1rem",
                  minHeight: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
            <div className="form-wrapper col-sm-12 col-md-6 col-lg-6">
              <div className="p-4 text-black ">
                <form
                  className="login-form"
                  autoComplete="off"
                  onSubmit={handleSubmit}
                >
                  <div className="d-flex align-items-center mb-3 pb-1">
                    <i
                      className="fas fa-cubes fa-2x me-3"
                      style={{ color: "#ff6219" }}
                    ></i>
                    <span className="h1 fw-bold mb-0">Cosmical Events</span>
                  </div>

                  <h5
                    className="fw-normal mb-3 pb-3"
                    style={{ letterSpacing: "1px" }}
                  >
                    Sign into your account
                  </h5>

                  <div className="form-outline mb-4">
                    <label className="form-label" htmlFor="email">
                      Email address
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="form-control form-control-lg"
                      value={Input.email}
                      onChange={(event) =>
                        setInput({ ...Input, email: event.target.value })
                      }
                    />
                  </div>

                  <div className="form-outline mb-4">
                    <label className="form-label" htmlFor="password">
                      Password
                    </label>
                    <input
                      type="password"
                      id="password"
                      name="password"
                      className="form-control form-control-lg"
                      value={Input.password}
                      onChange={(event) =>
                        setInput({
                          ...Input,
                          password: event.target.value,
                        })
                      }
                    />
                  </div>

                  <div className="pt-1 mb-4">
                    <button
                      className="btn btn-dark btn-lg btn-block"
                      type="submit"
                    >
                      Login
                    </button>
                  </div>

                  <a className="small text-muted" href="#!">
                    Forgot password?
                  </a>
                  <p className="pb-lg-2" style={{ color: "#393f81" }}>
                    Don't have an account?{" "}
                    <a
                      href="/forgot-pass"
                      onClick={OnRegister}
                      style={{ color: "white", backgroundColor: "black" }}
                    >
                      Register here
                    </a>
                  </p>
                  <p className="pb-lg-2" style={{ color: "#393f81" }}>
                    Don't have an account (only for vendors)?{" "}
                    <a
                      href="/signup"
                      onClick={OnVendor}
                      style={{ color: "white", backgroundColor: "black" }}
                    >
                      Register here
                    </a>
                  </p>

                  <a href="#!" className="small text-muted">
                    Terms of use.
                  </a>
                  <a href="#!" className="small text-muted">
                    Privacy policy
                  </a>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default UserLogin;
