import React from 'react'

const User = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '500px', marginTop: '200px' }}>
      <h1>User Dashboard </h1>
    </div>
  )
}

export default User