import React from "react";
import "./WeddingVendors.css";
const WeddingVendors = () => {
  return (
    <div>
      <section id="card-top-wed-ved">
        <div className="container ">
          <div className="image-round">
            <img
              src="assets/Images/sectionBgImages/element-139.jpg"
              className="spin-up"
              alt="..."
            />
          </div>
          <div className="row align-items-center">
            <div className="centered-container">
              <h2
                className="section-heading"
                data-aos="zoom-in-down"
                data-aos-delay="100"
                data-aos-duration="900"
              >
                Cosmic Event Heading...
              </h2>
              <p
                className="section-para"
                data-aos="zoom-in-up"
                data-aos-delay="150"
                data-aos-duration="900"
              >
                Cosmic Event card description in up to 20 words.
              </p>
            </div>
          </div>
          <div className="row align-items-center">
            <div
              className="col-sm-12 col-md-6 col-lg-3 "
              data-aos="zoom-in"
              data-aos-delay="300"
              data-aos-easing="ease-in-sine"
            >
              <figure class="image-block">
                <h1>The Event</h1>
                <img
                  src="https://images.pexels.com/photos/1680140/pexels-photo-1680140.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                  alt=""
                />
                <figcaption>
                  <h3>More Info</h3>
                  <p>
                    Pellentesque habitant morbi tristique senectus et netus et
                    malesuada fames ac turpis egestas. Vestibulum tortor quam,
                    feugiat vitae, ultricies eget, tempor sit amet, ante. Donec
                    eu libero sit amet quam egestas semper. Aenean ultricies mi
                    vitae est. Mauris placerat eleifend leo.
                  </p>
                  <button>More Info</button>
                </figcaption>
              </figure>
            </div>
            <div
              className="col-sm-12 col-md-6 col-lg-3 "
              data-aos="zoom-in"
              data-aos-delay="300"
              data-aos-easing="ease-in-sine"
            >
              <figure class="image-block">
                <h1>The Event</h1>
                <img
                  src="https://images.pexels.com/photos/1680140/pexels-photo-1680140.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                  alt=""
                />
                <figcaption>
                  <h3>More Info</h3>
                  <p>
                    Pellentesque habitant morbi tristique senectus et netus et
                    malesuada fames ac turpis egestas. Vestibulum tortor quam,
                    feugiat vitae, ultricies eget, tempor sit amet, ante. Donec
                    eu libero sit amet quam egestas semper. Aenean ultricies mi
                    vitae est. Mauris placerat eleifend leo.
                  </p>
                  <button>More Info</button>
                </figcaption>
              </figure>
            </div>
            <div
              className="col-sm-12 col-md-6 col-lg-3 "
              data-aos="zoom-in"
              data-aos-delay="300"
              data-aos-easing="ease-in-sine"
            >
              <figure class="image-block">
                <h1>The Event</h1>
                <img
                  src="https://images.pexels.com/photos/1680140/pexels-photo-1680140.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                  alt=""
                />
                <figcaption>
                  <h3>More Info</h3>
                  <p>
                    Pellentesque habitant morbi tristique senectus et netus et
                    malesuada fames ac turpis egestas. Vestibulum tortor quam,
                    feugiat vitae, ultricies eget, tempor sit amet, ante. Donec
                    eu libero sit amet quam egestas semper. Aenean ultricies mi
                    vitae est. Mauris placerat eleifend leo.
                  </p>
                  <button>More Info</button>
                </figcaption>
              </figure>
            </div>
            <div
              className="col-sm-12 col-md-6 col-lg-3 "
              data-aos="zoom-in"
              data-aos-delay="300"
              data-aos-easing="ease-in-sine"
            >
              <figure class="image-block">
                <h1>The Event</h1>
                <img
                  src="https://images.pexels.com/photos/1680140/pexels-photo-1680140.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                  alt=""
                />
                <figcaption>
                  <h3>More Info</h3>
                  <p>
                    Pellentesque habitant morbi tristique senectus et netus et
                    malesuada fames ac turpis egestas. Vestibulum tortor quam,
                    feugiat vitae, ultricies eget, tempor sit amet, ante. Donec
                    eu libero sit amet quam egestas semper. Aenean ultricies mi
                    vitae est. Mauris placerat eleifend leo.
                  </p>
                  <button>More Info</button>
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WeddingVendors;
