// src/hooks/useAuth.js
import { useNavigate } from 'react-router';
import secureLocalStorage from "react-secure-storage";

const useAuth = () => {
    const token = secureLocalStorage.getItem("token");
    const navigate = useNavigate();

    const isAuthenticated = () => {
        return !!token;
    };

    const redirectToLogin = () => {
        navigate('/login');
    };

    return {
        isAuthenticated,
        redirectToLogin
    };
};

export default useAuth;
