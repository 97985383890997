import React from 'react'

const EventCoordinator = () => {
  return (
    <div style={{display:'flex',justifyContent:'center',marginBottom:'500px',marginTop:'200px'}}>
    <h1>EventCoordinator Dashboard </h1>
  </div>
  )
}

export default EventCoordinator