import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import 'remixicon/fonts/remixicon.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Header from './Modules/Common/Header/Header';
import Footer from './Modules/Common/Footer/Footer';
import LandingPage from './Modules/Common/LandingPage/LandingPage';
import UserLogin from './Modules/Common/Login/UserLogin';
import UserRegistration from './Modules/Common/Registration/UserRegistration';
import VendorRegistration from './Modules/Common/Registration/VendorRegistration';
import VendorListingPage from './Modules/Common/Vendor/VendorListingPage';
import VendorInfoPage from './Modules/Common/Vendor/VendorInfoPage';
import ContactUs from './Modules/Common/ContactUs/ContactUs';
import FourNotFour from './Modules/Common/FourNotFour/FourNotFour';

import PrivateRoute from './routers/PrivateRoute';
import EventCoordinator from './Modules/EventCoordinator/EventCoordinator';
import Admin from './Modules/Admin/Admin';
import User from './Modules/User/User';
import Vendor from './Modules/Vendor/Vendor';
import secureLocalStorage from "react-secure-storage";
import {jwtDecode} from "jwt-decode";
import useDashboardCheck from './hooks/useDashboardCheck ';

function App() {
    useEffect(() => {
        AOS.init({
            duration: 650,
            once: true
        });
    }, []);

    const isDashboardRoute = useDashboardCheck();

    const token = secureLocalStorage.getItem("token");
    let role = null;
    if (token) {
        try {
            const decoded = jwtDecode(token);
            role = decoded ? decoded.role : null;
        } catch (error) {
            console.error("Error decoding token:", error);
        }
    }

    
    const showHeaderFooter = !isDashboardRoute || !['VENDOR', 'ADMIN', 'EVENT-COORDINATOR'].includes(role);

    return (
        <div>
            {showHeaderFooter && <Header />}
            <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/login" element={<UserLogin />} />
                <Route path="/user-registration" element={<UserRegistration />} />
                <Route path="/vendor-registration" element={<VendorRegistration />} />
                <Route path="/vendor-list" element={<VendorListingPage />} />
                <Route path="/vendor-info" element={<VendorInfoPage />} />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="*" element={<FourNotFour />} />

                <Route
                    path="/user-dashboard"
                    element={
                        <PrivateRoute roles={['USER']}>
                            <User />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/vendor-dashboard"
                    element={
                        <PrivateRoute roles={['VENDOR']}>
                            <Vendor />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/admin-dashboard"
                    element={
                        <PrivateRoute roles={['ADMIN']}>
                            <Admin />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/event-coordinator-dashboard"
                    element={
                        <PrivateRoute roles={['EVENT-COORDINATOR']}>
                            <EventCoordinator />
                        </PrivateRoute>
                    }
                />
            </Routes>
            {showHeaderFooter && <Footer />}
        </div>
    );
}

function AppWrapper() {
    return (
        <Router>
            <App />
        </Router>
    );
}

export default AppWrapper;
