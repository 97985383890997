import React, { useState } from "react";
import "./Faq.css";

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <div>
      <section id="faq">
        <div className="container ">
          <div className="row align-items-center">
            <div className="centered-container">
              <h2
                className="section-heading"
                data-aos="zoom-in-down"
                data-aos-delay="100"
                data-aos-duration="900"
              >
                Frequently Asked Questions
              </h2>
              <p
                className="section-para text-border"
                data-aos="zoom-in-up"
                data-aos-delay="150"
                data-aos-duration="900"
              >
                Overview of our services, mission, and general inquiries.
              </p>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="faq-image col-12 col-md-5 col-lg-6">
              <img src="assets/Images/weddingg.png" alt="faq image" />
            </div>
            <div className="col-12 col-md-5 col-lg-6">
              <div className="faq-container">
                <div className="accordion__wrapper">
                  <h1 className="accordion__title">
                    Frequently Asked Questions
                  </h1>
                  {faqData.map((item, index) => (
                    <div key={index} className="accordion">
                      <div
                        className="accordion__header"
                        onClick={() => handleToggle(index)}
                      >
                        <h2 className="accordion__question">{item.question}</h2>
                        <span className="accordion__icon">
                          <i
                            className={`ri-${
                              activeIndex === index ? "subtract" : "add"
                            }-line`}
                          />
                        </span>
                      </div>
                      <div
                        className={`accordion__content ${
                          activeIndex === index ? "active" : ""
                        }`}
                      >
                        <p className="accordion__answer">{item.answer}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const faqData = [
  {
    question: "Why is the moon sometimes out during the day?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut. Ut tortor pretium viverra suspendisse potenti.",
  },
  {
    question: "Why is the sky blue?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut. Ut tortor pretium viverra suspendisse potenti.",
  },
  {
    question: "Why is the moon sometimes out during the day?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut. Ut tortor pretium viverra suspendisse potenti.",
  },
  {
    question: "Why is the sky blue?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut. Ut tortor pretium viverra suspendisse potenti.",
  },
  {
    question: "Why is the moon sometimes out during the day?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut. Ut tortor pretium viverra suspendisse potenti.",
  },
  {
    question: "Why is the sky blue?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut. Ut tortor pretium viverra suspendisse potenti.",
  },
  // Add more FAQ items here
];

export default Faq;
