import React, { useState } from "react";
import Select from 'react-select';
import './Slider.css';
import { PiDressBold } from "react-icons/pi";
import { MdOutlineLocalFlorist, MdOutlineCake, MdOutlinePhotoCamera } from "react-icons/md";
import { GiMusicalNotes } from "react-icons/gi";
import { useNavigate } from 'react-router';

const statesAndCities = [
  { label: "Maharashtra", cities: ["Mumbai", "Pune", "Nagpur"] },
  { label: "Tamil Nadu", cities: ["Chennai", "Coimbatore", "Madurai"] },
  { label: "West Bengal", cities: ["Kolkata", "Howrah", "Durgapur"] },
];

const vendorTypes = [
  'Venues', 'Wedding Caterers', 'Photographers', 'Decorators',
  'Makeup Artists', 'Mehandi Artist', 'DJ and Multimedia',
  'Choreographers', 'Wedding Entertainment', 'Bridal Wear',
  'Groom Wear', 'Jewellery', 'Wedding Pandit',
  'Wedding Transportation', 'Party Places', 'Tent House',
  'Hospitality', 'Band', 'Wedding Cakes'
];

const Slider = () => {
  let navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = (option) => {
    setSelectedOption(option);
  };

  return (
    <div className="">
      <div id="myCarousel" className="carousel slide" data-ride="carousel">
        <div className="carousel-inner">
          <div className="item active">
            <img src="assets/Images/mainSliders/slider-1.jpg" alt="Kolkata" />
          </div>
          <div className="item">
            <img src="assets/Images/mainSliders/slider-2.jpg" alt="Chennai" />
          </div>
        </div>
        <a className="left carousel-control" href="#myCarousel" data-slide="prev">
          <span className="glyphicon glyphicon-chevron-left"></span>
          <span className="sr-only">Previous</span>
        </a>
        <a className="right carousel-control" href="#myCarousel" data-slide="next">
          <span className="glyphicon glyphicon-chevron-right"></span>
          <span className="sr-only">Next</span>
        </a>
      </div>
      <div className="slider-content">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-12 mx-auto">
              <h1>Find the Perfect Wedding Vendor</h1>
              <p className="lead text-white text-center">
                Search over 360,000 wedding vendors with reviews, pricing, availability and more
              </p>
              <form className="" action="#">
            <div className="slider-form rounded">
              <div className="row align-items-center g-0">
                <div className="col-md-10">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="">
                        <div className="input-group">
                          <span className="d-flex align-items-center px-3 before-input-box">
                            <i className="fa fa-search"></i>
                          </span>
                          <select
                            className="form-control form-light listing-category category-input"
                          >
                            <option value="">Select Vendor Type</option>
                            {vendorTypes.map((vendorType, index) => (
                              <option key={index} value={vendorType.toLowerCase().replace(/\s/g, '-')}>
                                {vendorType}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-md-6 left-border">
                      <div className="">
                        <div className="input-group">
                          <span className="d-flex align-items-center px-3 before-input-box">
                            in
                          </span>
                          <Select
                            className="form-control form-light listing-category category-input"
                            options={statesAndCities.map(state => ({
                              value: state.label,
                              label: state.label,
                              options: state.cities.map(city => ({
                                value: city,
                                label: city,
                              }))
                            }))}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="d-grid">
                    <button type="submit" className="btn btn-default text-nowrap btn-block" onClick={() => navigate('/vendor-list')}>
                      Search Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
              <p className="lead text-white text-center">
                Or browse featured categories
              </p>
              <div className="slider-category listing-category-layout-1">
                <a href="listing-category/cake/index.html">
                  <i><MdOutlineCake /></i> Cake
                </a>
                <a href="listing-category/dress">
                  <i><PiDressBold /></i>
                  Dress
                </a>
                <a href="listing-category/florist/index.html">
                  <i><MdOutlineLocalFlorist /></i> Florist
                </a>
                <a href="listing-category/music/index.html">
                  <i><GiMusicalNotes /></i> Music
                </a>
                <a href="listing-category/photographer/index.html">
                  <i><MdOutlinePhotoCamera /></i> Photographer
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;
