import React from 'react'

const Admin = () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '500px', marginTop: '200px' }}>
            <h1>Admin Dashboard </h1>
        </div>
    )
}

export default Admin