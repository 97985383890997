import React from "react";
import { FaRegHeart, FaRegStar } from "react-icons/fa6";

import "./WeddingListings.css";
const WeddingListings = () => {
  return (
    <div>
      <section id="wed-list" className="wed-list">
        <div className="container ">
          <div className="row align-items-center">
            <div className="centered-container">
              <h2
                className="section-heading"
                data-aos="zoom-in-down"
                data-aos-delay="100"
                data-aos-duration="900"
              >
                Premier Professionals
              </h2>
              <p
                className="section-para text-border"
                data-aos="zoom-in-up"
                data-aos-delay="150"
                data-aos-duration="900"
              >
                Meet the top-tier vendors ready to make your wedding magical.
              </p>
            </div>
          </div>
          <div className="row align-items-center">
            <div
              className="col "
              data-aos="zoom-in"
              data-aos-delay="300"
              data-aos-easing="ease-in-sine"
            >
              <div className="card">
                <div className="image-container">
                  <div className="first">
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="discount">Spotlight</span>
                      <span className="wishlist">
                        <i>
                          <FaRegHeart />
                        </i>
                      </span>
                    </div>
                  </div>
                  <div className="second">
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="discount">highlights</span>
                    </div>
                  </div>
                  <img
                    src="assets/Images/rainbow-room.jpg"
                    className="img-fluid rounded thumbnail-image"
                    alt="vender_card"
                  />
                </div>
                <div className="product-detail-container p-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="product-name">
                      White traditional long product
                    </h5>
                    <div className="d-flex flex-column mb-2">
                      <span className="new-price">3,9900</span>
                      <small className="old-price text-right">59,099</small>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center pt-1">
                    <div>
                      <i className="rating-star">
                        <FaRegStar />
                      </i>
                      <span className="rating-number m-2">4.8</span>
                    </div>
                    <span className="inq-button">Quote</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col "
              data-aos="zoom-in"
              data-aos-delay="300"
              data-aos-easing="ease-in-sine"
            >
              <div className="card">
                <div className="image-container">
                  <div className="first">
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="discount">Spotlight</span>
                      <span className="wishlist">
                        <i>
                          <FaRegHeart />
                        </i>
                      </span>
                    </div>
                  </div>
                  <div className="second">
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="discount">highlights</span>
                    </div>
                  </div>
                  <img
                    src="assets/Images/rainbow-room.jpg"
                    className="img-fluid rounded thumbnail-image"
                  />
                </div>
                <div className="product-detail-container p-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="product-name">Long sleeve denim jacket</h5>
                    <div className="d-flex flex-column mb-2">
                      <span className="new-price">3,9900</span>
                      <small className="old-price text-right">59,099</small>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center pt-1">
                    <div>
                      <i className="rating-star">
                        <FaRegStar />
                      </i>
                      <span className="rating-number">4.8</span>
                    </div>
                    <span className="inq-button">Quote</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col "
              data-aos="zoom-in"
              data-aos-delay="300"
              data-aos-easing="ease-in-sine"
            >
              <div className="card">
                <div className="image-container">
                  <div className="first">
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="discount">Spotlight</span>
                      <span className="wishlist">
                        <i>
                          <FaRegHeart />
                        </i>
                      </span>
                    </div>
                  </div>
                  <div className="second">
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="discount">highlights</span>
                    </div>
                  </div>
                  <img
                    src="assets/Images/rainbow-room.jpg"
                    className="img-fluid rounded thumbnail-image"
                  />
                </div>
                <div className="product-detail-container p-3">
                  <div className="d-flex justify-content-between">
                    <h5 className="product-name">Hush Puppies</h5>
                    <div className="d-flex flex-column mb-2">
                      <span className="new-price">3,9900</span>
                      <small className="old-price text-right">59,099</small>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center pt-1">
                    <div>
                      <i className="rating-star">
                        <FaRegStar />
                      </i>
                      <span className="rating-number">4.2</span>
                    </div>
                    <span className="inq-button">Quote</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col "
              data-aos="zoom-in"
              data-aos-delay="300"
              data-aos-easing="ease-in-sine"
            >
              <div className="card">
                <div className="image-container">
                  <div className="first">
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="discount">Spotlight</span>
                      <span className="wishlist">
                        <i>
                          <FaRegHeart />
                        </i>
                      </span>
                    </div>
                  </div>
                  <div className="second">
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="discount">highlights</span>
                    </div>
                  </div>
                  <img
                    src="assets/Images/rainbow-room.jpg"
                    className="img-fluid rounded thumbnail-image"
                  />
                </div>
                <div className="product-detail-container p-3">
                  <div className="d-flex justify-content-between">
                    <h5 className="product-name">Athens skirt</h5>
                    <div className="d-flex flex-column mb-2">
                      <span className="new-price">3,9900</span>
                      <small className="old-price text-right">59,099</small>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center pt-1">
                    <div>
                      <i className="rating-star">
                        <FaRegStar />
                      </i>
                      <span className="rating-number">3.8</span>
                    </div>
                    <span className="inq-button">Quote</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col "
              data-aos="zoom-in"
              data-aos-delay="300"
              data-aos-easing="ease-in-sine"
            >
              <div className="card">
                <div className="image-container">
                  <div className="first">
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="discount">Spotlight</span>
                      <span className="wishlist">
                        <i>
                          <FaRegHeart />
                        </i>
                      </span>
                    </div>
                  </div>
                  <div className="second">
                    <div className="d-flex justify-content-between align-items-center">
                      <span className="discount">highlights</span>
                    </div>
                  </div>
                  <img
                    src="assets/Images/rainbow-room.jpg"
                    className="img-fluid rounded thumbnail-image"
                  />
                </div>
                <div className="product-detail-container p-3">
                  <div className="d-flex justify-content-between">
                    <h5 className="product-name">Hush Puppies</h5>
                    <div className="d-flex flex-column mb-2">
                      <span className="new-price">39,900</span>
                      <small className="old-price text-right">59,099</small>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center pt-1">
                    <div>
                      <i className="rating-star">
                        <FaRegStar />
                      </i>
                      <span className="rating-number">4.2</span>
                    </div>
                    <span className="inq-button">Quote</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WeddingListings;
