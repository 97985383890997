// src/LandingPage.js
import React from 'react';
import Footer from '../../Common/Footer/Footer'
import Content from '../../Common/Content/Content'

import Header from '../Header/Header'





const LandingPage = () => {


  return (
    <div>
 
      <Content />
      
    </div>
  );
};

export default LandingPage;
