import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import  secureLocalStorage  from  "react-secure-storage";
import { jwtDecode } from "jwt-decode";
 const BASE_URL = process.env.REACT_APP_BASE_URL;
export const Login = createAsyncThunk(
    "UserLogin",
    async (arg, { rejectWithValue }) => {
        try {
            console.log(`-------BASE_URL ---------`,BASE_URL);
            const response = await axios.post(`${BASE_URL}/api/v1/User/login`, arg);
            console.log("------------response----------", response);
            const token = response.data.data?.access_token;
            if (token) {
                secureLocalStorage.setItem("token", token);
                secureLocalStorage.setItem("user", JSON.stringify(response.data.data?.user)); 
                const decoded = jwtDecode(token);
                return decoded.role; // Return the role
            } else {
                console.error("No access token found in the response");
                return rejectWithValue("No access token found in the response");
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const loginSlice = createSlice({
    name: 'loginSlice',
    initialState: {
        commentData: [],
        IsAdding: false,
        loading: false,
        error: null,
        role: null, 
    },
    reducers: {
        IsAddingOpen: (state, action) => {
            state.IsAdding = true;
        },
        IsAddingClose: (state, action) => {
            state.IsAdding = false;
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(Login.pending, (state) => {
                state.loading = true;
            })
            .addCase(Login.fulfilled, (state, action) => {
                state.loading = false;
                state.role = action.payload; 
                console.log(`-------action role ---------`, state.role);
            })
            .addMatcher(
                (action) => action.type.endsWith('/rejected') && action.meta.rejectedWithValue,
                (state, action) => {
                    state.loading = false;
                    state.error = action.payload.message; 
                }
            );
    },
});

export const { IsAddingOpen} = loginSlice.actions;
export default loginSlice.reducer;
