import React, { useState } from "react";
import "./UserRegistration.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

const { UserRegister } = require("../../../context/Slice/RegisterSlice");

const UserRegistration = () => {
  let navigate = useNavigate();
  const Dispatch = useDispatch();
  const [Input, setInput] = useState({
    fullname: "",
    email: "",
    password: "",
    role: "USER",
    mobile_number: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    const response = await Dispatch(UserRegister(Input));
    if (response.payload.status === 200) {
      // Changed '200' to 200
      navigate("/login");
    } else {
      console.log("something wrong");
    }
    console.log("response----->", response);
  };

  return (
    <section className="user-reg vh-100 mt-3">
      <div className="container py-5 py-sm-5 py-xl-5 h-100">
        <div className="row d-flex h-100">
          <div className="col">
            <div className="card card-registration my-4 registration-container ml-5">
              <div className="row g-0">
                <div className="col-xl-6 col-md-6 d-none d-sm-block">
                  <img
                    src="assets/Images/login-img.jpg"
                    alt="Sample photo"
                    className="img-fluid"
                    style={{
                      borderTopLeftRadius: ".25rem",
                      borderBottomLeftRadius: ".25rem",
                      minHeight: "600px",
                    }}
                  />
                </div>
                <div className="col-xl-6 col-md-6 mt-5">
                  <div className="card-body text-black">
                    <h3 className="mb-5 text-uppercase heading-signUp">
                      {" "}
                      Sign Up
                    </h3>
                    <div className="coloum">
                      <form autoComplete="off" onSubmit={handleSubmit}>
                        <div data-mdb-input-init className="form-outline mb-4">
                          <label className="form-label" for="form3Example1cg">
                            Full Name
                          </label>
                          <input
                            type="text"
                            id="fullname"
                            name="fullname"
                            value={Input.fullname}
                            onChange={(event) =>
                              setInput({
                                ...Input,
                                fullname: event.target.value,
                              })
                            }
                            className="form-control form-control-lg"
                          />
                        </div>

                        <div data-mdb-input-init className="form-outline mb-4">
                          <label className="form-label" for="form3Example3cg">
                            Your Email
                          </label>
                          <input
                            type="email"
                            id="email"
                            name="email"
                            value={Input.email}
                            onChange={(event) =>
                              setInput({ ...Input, email: event.target.value })
                            }
                            className="form-control form-control-lg"
                          />
                        </div>

                        <div data-mdb-input-init className="form-outline mb-4">
                          <label className="form-label" for="form3Example4cg">
                            Password
                          </label>
                          <input
                            type="password"
                            id="password"
                            name="password"
                            value={Input.password}
                            onChange={(event) =>
                              setInput({
                                ...Input,
                                password: event.target.value,
                              })
                            }
                            className="form-control form-control-lg"
                          />
                        </div>

                        <div data-mdb-input-init className="form-outline mb-4">
                          <label className="form-label" for="form3Example4cg">
                            Mobile Number
                          </label>
                          <input
                            type="text"
                            id="mobile_number"
                            name="mobile_number"
                            value={Input.mobile_number}
                            onChange={(event) =>
                              setInput({
                                ...Input,
                                mobile_number: event.target.value,
                              })
                            }
                            className="form-control form-control-lg"
                          />
                        </div>

                        <div className="form-check d-flex justify-content-center mb-5">
                          <input
                            className="form-check-input me-2"
                            type="checkbox"
                            value=""
                            id="form2Example3cg"
                          />
                          <label
                            className="form-check-label"
                            for="form2Example3g"
                          >
                            I agree all statements in{" "}
                            <a href="#!" className="text-body">
                              <u>Terms of service</u>
                            </a>
                          </label>
                        </div>

                        <div className="d-flex justify-content-center">
                          <button
                            onClick={handleSubmit}
                            type="button"
                            data-mdb-button-init
                            data-mdb-ripple-init
                            className="btn btn-success btn-block btn-lg gradient-custom-4 text-body"
                          >
                            Register
                          </button>
                        </div>

                        <p className="text-center text-muted mt-5 mb-0">
                          Have already an account?{" "}
                          <a href="#!" className="fw-bold text-body">
                            <u onClick={() => navigate("/login")}>Login here</u>
                          </a>
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UserRegistration;
