import React from 'react';
import { Navigate } from 'react-router-dom';
import secureLocalStorage from "react-secure-storage";
import {jwtDecode} from "jwt-decode";

const PrivateRoute = ({ children, roles }) => {
    const token = secureLocalStorage.getItem("token");

    if (!token) {
        return <Navigate to="/login" />;
    }

    let role;
    try {
        const decoded = jwtDecode(token);
        role = decoded ? decoded.role : null;
    } catch (error) {
        console.error("Error decoding token:", error);
        return <Navigate to="/login" />;
    }

    if (roles && role && roles.indexOf(role) === -1) {
        return <Navigate to="/" />;
    }

    return children;
};

export default PrivateRoute;
