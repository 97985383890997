// src/VendorInfoPage.js
import React, { useEffect, useState } from 'react';
import './VendorInfoPage.css';
import { FaImage, FaRegFileAlt, FaMapMarker, FaStar, FaStarHalfAlt, FaGrinStars, FaRupeeSign } from 'react-icons/fa';
import { FaPerson } from "react-icons/fa6";
import { useNavigate } from 'react-router';
import AOS from 'aos';
import 'aos/dist/aos.css';
import useAuth from '../../../hooks/useAuth';
 


const VendorInfoPage = () => {
   
    let navigate = useNavigate();
    const { isAuthenticated, redirectToLogin } = useAuth(); // Use the custom hook

    const [animateBars, setAnimateBars] = useState(false);

    useEffect(() => {
        if (!isAuthenticated()) {
            redirectToLogin();
        }

      
        AOS.init({
            duration: 1000,
            once: true,
            offset: 100,
        });
        setAnimateBars(true);
    }, []);
    return (
        <div>


            <div id='vendorInfoPage'>
                <div>
                    <div className="">
                        <div className="carousel slide" data-ride="carousel" data-aos="fade-up">
                            <div className="carousel-inner">
                                <div className="item active">
                                    <img src="assets/Images/WeddingDir-List.jpg" alt="Kolkata" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className="row">
                        <div className="col-md-8">
                            {/* Vendor Type Section */}
                            <div className="content-section" id="vendor-type" data-aos="fade-right">
                                <h1 className="vendor-name">Sprinkles Bakery</h1>
                                <h3 className="vendor-location"><FaMapMarker /> Pune</h3>
                                <h4 className="vendor-rating">
                                    <FaStar className="gold-star" />
                                    <FaStar className="gold-star" />
                                    <FaStar className="gold-star" />
                                    <FaStar className="gold-star" />
                                    <span> Rating</span>
                                </h4>
                            </div>
                            {/* Services Section */}
                            <div className="content-section position-stickey sticky-nav" id="services" data-aos="fade-left">
                                <div>
                                    <ul className="nav flex-row nav-pills menu-sidebar">
                                        <li className="nav-item">
                                            <a className="nav-link" href="#about-us"><FaRegFileAlt /> About Us</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#gallery"><FaImage /> Gallery</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#location"><FaMapMarker /> Location</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="#review"><FaStar /> Review</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            {/* About Us Section */}
                            <div className="content-section" id="about-us" data-aos="fade-right">
                                <h3><FaRegFileAlt /> About Us</h3>
                                <p>
                                    Celebz The Limo Services, Lorem ipsum dolosit amet, consectetur adipiscing elit. Integer tortor velit, placerat sit amet pellentesque id, semper nec risus. Aenean ac libero interdum, egestas nisi non, auctor urna. Nam lacus turpis, vulputate id trum id risus. Mauris tincidunt feugiat egestas. Cras sit amet velit eleifend, maximus mauris ut, egestas tortor. Cras nec maximus eros. Nam placerat velit ut suscipit consequat. Suspendisse leo sem, porttitor semper ligula ac, tincidunt dignissim sem.
                                </p>
                                <p>
                                    Cras id nulla hendrerit, tristique sapien vitae, convallis nunc. Mauris mi urna, pulvinar vel posuere ullamcorper, viverra eget sem. Suspendisse lobortis imperdiet augue quis rutrum. Maecenas hendrerit, ligula sit amet ultrices placerat, erat est bibendum nisl, ut faucibus risus mauris hendrerit mauris. Vestibulum sit amet scelerisque quam, ut molestie lorem. In sit amet ipsum ut erat congue pharetra. Cras fermentum iaculis pellentesque. Nulla ac purus varius, pharetra arcu vel, imperdiet erat. Fusce molestie est ut tempor interdum. Curabitur eleifend leo vel mattis euismod. Aenean iaculis mauris libero, ut laoreet magna rutrum eu. Sed vulputate iaculis metus, et vulputate velit sagittis ut. Suspendisse vel tortor nec eros consequat imperdiet lobortis quis quam. Nullam faucibus, sapien quis hendrerit sodales, ligula lectus ullamcorper nulla, et viverra mi orci ultrices tellus. Vestibulum semper euismod nisl eget accumsan. Ut vulputate bibendum dolor, id pulvinar nunc mattis quis.
                                </p>
                            </div>

                            {/* Gallery Section */}
                            <div className="content-section" id="gallery" data-aos="fade-left">
                                <h3><FaImage /> Gallery</h3>
                                <div className="container">
                                    <div className="row gy-4">
                                        {Array(6).fill().map((_, index) => (
                                            <div className="col-lg-4 col-md-6" key={index} >
                                                <div className="card">
                                                    <div className="card-img">
                                                        <img src="assets/Images/channai.jpg" alt="" className="img-fluid" />
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            {/* Review Section */}
                            <div className="content-section" id="review" data-aos="fade-right">
                                <h3><FaStarHalfAlt /> Review</h3>
                                <div>
                                    <div className="review-section">
                                        <span className="progress-label"><FaGrinStars className="icon" /> Quality Service</span>
                                        <div className={`progress mb-2 ${animateBars ? 'progress-animation' : ''}`}>
                                            <div className="progress-bar bg-success" role="progressbar" style={{ width: '25%' }} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}>25%</div>
                                        </div>
                                        <span className="progress-label"><FaStar className="icon" /> Value of Money</span>
                                        <div className={`progress mb-2 ${animateBars ? 'progress-animation' : ''}`}>
                                            <div className="progress-bar bg-info" role="progressbar" style={{ width: '50%' }} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100}>50%</div>
                                        </div>
                                        <span className="progress-label"><FaPerson className="icon" /> Availability</span>
                                        <div className={`progress mb-2 ${animateBars ? 'progress-animation' : ''}`}>
                                            <div className="progress-bar bg-warning" role="progressbar" style={{ width: '75%' }} aria-valuenow={75} aria-valuemin={0} aria-valuemax={100}>75%</div>
                                        </div>
                                        <span className="progress-label"><FaRupeeSign className="icon" /> Budget Friendly</span>
                                        <div className={`progress mb-2 ${animateBars ? 'progress-animation' : ''}`}>
                                            <div className="progress-bar bg-danger" role="progressbar" style={{ width: '100%' }} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}>100%</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Location Section */}
                            <div className="content-section" id="location" data-aos="fade-left">
                                <h3><FaMapMarker /> Location</h3>
                                <div className="ratio ratio-16x9">
                                    <iframe title="Chennai Location" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15557.231843377076!2d80.24327361394452!3d13.052665218093516!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52638d5f58256f%3A0x375b1d832c9cf95c!2sT%20Nagar%2C%20Chennai%2C%20Tamil%20Nadu%20600017%2C%20India!5e0!3m2!1sen!2sus!4v1686945816121!5m2!1sen!2sus"></iframe>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            {/* Appointment Form */}
                            <div className="sticky-top appointment-form" data-aos="fade-up">
                                <span className='appointment-heading'>Book an Appointment</span>
                                <form>
                                    <div className="mb-3 mt-5">
                                        <label htmlFor="date" className="form-label">Date</label>
                                        <input type="date" className="form-control" id="date" />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="note" className="form-label">Note</label>
                                        <textarea className="form-control" id="note" placeholder="Note" rows="4"></textarea>
                                    </div>
                                    <button type="submit" className="btn btn-primary" onClick={() => navigate('/Book')}>Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
};

export default VendorInfoPage;
