import React from 'react';
// import "./Content.css";

import WeddingFeatures from './WeddingFeatures/WeddingFeatures';
import WeddingListings from './Top-Wedding-Listings/WeddingListings';
import WeddingVendors from './Top-Wedding-Vendors/WeddingVendors';
import PopularLocations from './Top-Popular-Locations/PopularLocations';
import TeamSection from './Team-Section/TeamSection';
import Slider from '../Sliders/Home-Slider/Slider';
import ContactUs from '../ContactUs/ContactUs';
import Faq from '../Faq/Faq';
import Testimonial from '../Testimonial/Testimonial';
import AboutUs from '../AboutUs/AboutUs';



const Content = () => {
    return (
        <div>
            <Slider />
            <WeddingFeatures />
            <WeddingListings />
            <WeddingVendors />
            <PopularLocations />
            {/* <TeamSection /> */}
            <Testimonial/>
            <ContactUs />
           
            <Faq />
        </div>

    );
}

export default Content;
