import React, { useState } from "react";
import "./VendorListingPage.css"; // Import the CSS file
import { useNavigate } from "react-router";

import Button from "../Button/Button";

const vendorData = [
  {
    imgsrc: "assets/Images/jaipur-image.jpg",
    serviceType: "VENUES",
    description:
      "Beautiful venue with amazing views Beautiful venue with amazing viewsBeautiful venue with amazing viewsBeautiful venue with amazing viewsBeautiful venue with amazing viewsBeautiful venue with amazing viewsBeautiful venue with amazing viewsBeautiful venue with amazing viewsBeautiful venue with amazing viewsBeautiful venue with amazing viewsBeautiful venue with amazing viewsBeautiful venue with amazing viewsBeautiful venue with amazing viewsBeautiful venue with amazing viewsBeautiful venue with amazing viewsBeautiful venue with amazing viewsBeautiful venue with amazing viewsBeautiful venue with amazing views",
    priceRange: "Rs 50000 - RS 100000",
    city: "Pune",
    icon: "fa-building",
  },

  {
    imgsrc: "assets/Images/wedding-food.jpg",
    serviceType: "WEDDING CATERERS",
    description:
      "Delicious food with customizable menu options Delicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu options",
    priceRange: "Rs 50000 - RS 500000",
    city: "Jaipur",
    icon: "fa-utensils",
  },
  {
    imgsrc: "assets/Images/wedding-photographer.jpg",
    serviceType: "PHOTOGRAPHERS",
    description:
      "Professional photography services for your special day.Professional photography services for your special dayProfessional photography services for your special dayProfessional photography services for your special day Professional photography services for your special dayProfessional photography services for your special day...Professional photography services for your special day...Professional photography services for your special day...Professional photography services for your special day...Professional photography services for your special day...Professional photography services for your special day...Professional photography services for your special day...Professional photography services for your special day...Professional photography services for your special day...Professional photography services for your special day...Professional photography services for your special day...Professional photography services for your special day....",
    priceRange: "Rs 50000 - RS 200000",
    city: "Delhi",
    icon: "fa-camera",
  },
  {
    imgsrc: "assets/Images/Wedding Florist.jpg",
    serviceType: "flowers ",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc a nisl cursus, tincidunt leo eu, elementum tellus. Phasellus vehicula eros eu fringilla pellentesque. Sed commodo nulla ipsum, ut dictum turpis malesuada a. Integer eu semper velit, et sagittis elit. Integer magna magna, vestibulum ac elit tincidunt, ornare dictum lacus. Etiam at metus auctor, pulvinar neque id, fringilla nisl. In nunc felis, pharetra sed lacus id, vehicula aliquet tellus. Donec ultrices felis non sem pulvinar condimentum. Nulla vel fermentum sapien, ut aliquet lacus. Donec vestibulum viverra orci id porta. Vestibulum lacinia nisl ut enim congue, quis bibendum eros suscipit. In sollicitudin nec ipsum quis scelerisque. Duis sit amet neque vestibulum, viverra orci id, dignissim leo.",
    priceRange: "Rs 50000 - RS 500000",
    city: "Jaipur",
    icon: "fa-utensils",
  },
  {
    imgsrc: "assets/Images/wedding-marraige-pandit.jpg",
    serviceType: "Pandit ",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc a nisl cursus, tincidunt leo eu, elementum tellus. Phasellus vehicula eros eu fringilla pellentesque. Sed commodo nulla ipsum, ut dictum turpis malesuada a. Integer eu semper velit, et sagittis elit. Integer magna magna, vestibulum ac elit tincidunt, ornare dictum lacus. Etiam at metus auctor, pulvinar neque id, fringilla nisl. In nunc felis, pharetra sed lacus id, vehicula aliquet tellus. Donec ultrices felis non sem pulvinar condimentum. Nulla vel fermentum sapien, ut aliquet lacus. Donec vestibulum viverra orci id porta. Vestibulum lacinia nisl ut enim congue, quis bibendum eros suscipit. In sollicitudin nec ipsum quis scelerisque. Duis sit amet neque vestibulum, viverra orci id, dignissim leo.",
    priceRange: "Rs 50000 - RS 500000",
    city: "Jaipur",
    icon: "fa-utensils",
  },

  {
    imgsrc: "assets/Images/wedding-dress.jpg",
    serviceType: "Wedding Dress ",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc a nisl cursus, tincidunt leo eu, elementum tellus. Phasellus vehicula eros eu fringilla pellentesque. Sed commodo nulla ipsum, ut dictum turpis malesuada a. Integer eu semper velit, et sagittis elit. Integer magna magna, vestibulum ac elit tincidunt, ornare dictum lacus. Etiam at metus auctor, pulvinar neque id, fringilla nisl. In nunc felis, pharetra sed lacus id, vehicula aliquet tellus. Donec ultrices felis non sem pulvinar condimentum. Nulla vel fermentum sapien, ut aliquet lacus. Donec vestibulum viverra orci id porta. Vestibulum lacinia nisl ut enim congue, quis bibendum eros suscipit. In sollicitudin nec ipsum quis scelerisque. Duis sit amet neque vestibulum, viverra orci id, dignissim leo.",
    priceRange: "Rs 50000 - RS 500000",
    city: "Jaipur",
    icon: "fa-utensils",
  },
  {
    imgsrc: "assets/Images/jaipur-image.jpg",
    serviceType: "VENUES",
    description:
      "Beautiful venue with amazing views Beautiful venue with amazing viewsBeautiful venue with amazing viewsBeautiful venue with amazing viewsBeautiful venue with amazing viewsBeautiful venue with amazing viewsBeautiful venue with amazing viewsBeautiful venue with amazing viewsBeautiful venue with amazing viewsBeautiful venue with amazing viewsBeautiful venue with amazing viewsBeautiful venue with amazing viewsBeautiful venue with amazing viewsBeautiful venue with amazing viewsBeautiful venue with amazing viewsBeautiful venue with amazing viewsBeautiful venue with amazing viewsBeautiful venue with amazing views",
    priceRange: "Rs 50000 - RS 100000",
    city: "Pune",
    icon: "fa-building",
  },

  {
    imgsrc: "assets/Images/wedding-food.jpg",
    serviceType: "WEDDING CATERERS",
    description:
      "Delicious food with customizable menu options Delicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu options",
    priceRange: "Rs 50000 - RS 500000",
    city: "Jaipur",
    icon: "fa-utensils",
  },
  {
    imgsrc: "assets/Images/wedding-photographer.jpg",
    serviceType: "PHOTOGRAPHERS",
    description:
      "Professional photography services for your special day.Professional photography services for your special dayProfessional photography services for your special dayProfessional photography services for your special day Professional photography services for your special dayProfessional photography services for your special day...Professional photography services for your special day...Professional photography services for your special day...Professional photography services for your special day...Professional photography services for your special day...Professional photography services for your special day...Professional photography services for your special day...Professional photography services for your special day...Professional photography services for your special day...Professional photography services for your special day...Professional photography services for your special day...Professional photography services for your special day....",
    priceRange: "Rs 50000 - RS 200000",
    city: "Delhi",
    icon: "fa-camera",
  },
  {
    imgsrc: "assets/Images/Wedding Florist.jpg",
    serviceType: "flowers ",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc a nisl cursus, tincidunt leo eu, elementum tellus. Phasellus vehicula eros eu fringilla pellentesque. Sed commodo nulla ipsum, ut dictum turpis malesuada a. Integer eu semper velit, et sagittis elit. Integer magna magna, vestibulum ac elit tincidunt, ornare dictum lacus. Etiam at metus auctor, pulvinar neque id, fringilla nisl. In nunc felis, pharetra sed lacus id, vehicula aliquet tellus. Donec ultrices felis non sem pulvinar condimentum. Nulla vel fermentum sapien, ut aliquet lacus. Donec vestibulum viverra orci id porta. Vestibulum lacinia nisl ut enim congue, quis bibendum eros suscipit. In sollicitudin nec ipsum quis scelerisque. Duis sit amet neque vestibulum, viverra orci id, dignissim leo.",
    priceRange: "Rs 50000 - RS 500000",
    city: "Jaipur",
    icon: "fa-utensils",
  },
  {
    imgsrc: "assets/Images/wedding-marraige-pandit.jpg",
    serviceType: "Pandit ",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc a nisl cursus, tincidunt leo eu, elementum tellus. Phasellus vehicula eros eu fringilla pellentesque. Sed commodo nulla ipsum, ut dictum turpis malesuada a. Integer eu semper velit, et sagittis elit. Integer magna magna, vestibulum ac elit tincidunt, ornare dictum lacus. Etiam at metus auctor, pulvinar neque id, fringilla nisl. In nunc felis, pharetra sed lacus id, vehicula aliquet tellus. Donec ultrices felis non sem pulvinar condimentum. Nulla vel fermentum sapien, ut aliquet lacus. Donec vestibulum viverra orci id porta. Vestibulum lacinia nisl ut enim congue, quis bibendum eros suscipit. In sollicitudin nec ipsum quis scelerisque. Duis sit amet neque vestibulum, viverra orci id, dignissim leo.",
    priceRange: "Rs 50000 - RS 500000",
    city: "Jaipur",
    icon: "fa-utensils",
  },

  {
    imgsrc: "assets/Images/wedding-dress.jpg",
    serviceType: "Wedding Dress ",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc a nisl cursus, tincidunt leo eu, elementum tellus. Phasellus vehicula eros eu fringilla pellentesque. Sed commodo nulla ipsum, ut dictum turpis malesuada a. Integer eu semper velit, et sagittis elit. Integer magna magna, vestibulum ac elit tincidunt, ornare dictum lacus. Etiam at metus auctor, pulvinar neque id, fringilla nisl. In nunc felis, pharetra sed lacus id, vehicula aliquet tellus. Donec ultrices felis non sem pulvinar condimentum. Nulla vel fermentum sapien, ut aliquet lacus. Donec vestibulum viverra orci id porta. Vestibulum lacinia nisl ut enim congue, quis bibendum eros suscipit. In sollicitudin nec ipsum quis scelerisque. Duis sit amet neque vestibulum, viverra orci id, dignissim leo.",
    priceRange: "Rs 50000 - RS 500000",
    city: "Jaipur",
    icon: "fa-utensils",
  },
  {
    imgsrc: "assets/Images/jaipur-image.jpg",
    serviceType: "VENUES",
    description:
      "Beautiful venue with amazing views Beautiful venue with amazing viewsBeautiful venue with amazing viewsBeautiful venue with amazing viewsBeautiful venue with amazing viewsBeautiful venue with amazing viewsBeautiful venue with amazing viewsBeautiful venue with amazing viewsBeautiful venue with amazing viewsBeautiful venue with amazing viewsBeautiful venue with amazing viewsBeautiful venue with amazing viewsBeautiful venue with amazing viewsBeautiful venue with amazing viewsBeautiful venue with amazing viewsBeautiful venue with amazing viewsBeautiful venue with amazing viewsBeautiful venue with amazing views",
    priceRange: "Rs 50000 - RS 100000",
    city: "Pune",
    icon: "fa-building",
  },

  {
    imgsrc: "assets/Images/wedding-food.jpg",
    serviceType: "WEDDING CATERERS",
    description:
      "Delicious food with customizable menu options Delicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu optionsDelicious food with customizable menu options",
    priceRange: "Rs 50000 - RS 500000",
    city: "Jaipur",
    icon: "fa-utensils",
  },
  {
    imgsrc: "assets/Images/wedding-photographer.jpg",
    serviceType: "PHOTOGRAPHERS",
    description:
      "Professional photography services for your special day.Professional photography services for your special dayProfessional photography services for your special dayProfessional photography services for your special day Professional photography services for your special dayProfessional photography services for your special day...Professional photography services for your special day...Professional photography services for your special day...Professional photography services for your special day...Professional photography services for your special day...Professional photography services for your special day...Professional photography services for your special day...Professional photography services for your special day...Professional photography services for your special day...Professional photography services for your special day...Professional photography services for your special day...Professional photography services for your special day....",
    priceRange: "Rs 50000 - RS 200000",
    city: "Delhi",
    icon: "fa-camera",
  },
  {
    imgsrc: "assets/Images/Wedding Florist.jpg",
    serviceType: "flowers ",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc a nisl cursus, tincidunt leo eu, elementum tellus. Phasellus vehicula eros eu fringilla pellentesque. Sed commodo nulla ipsum, ut dictum turpis malesuada a. Integer eu semper velit, et sagittis elit. Integer magna magna, vestibulum ac elit tincidunt, ornare dictum lacus. Etiam at metus auctor, pulvinar neque id, fringilla nisl. In nunc felis, pharetra sed lacus id, vehicula aliquet tellus. Donec ultrices felis non sem pulvinar condimentum. Nulla vel fermentum sapien, ut aliquet lacus. Donec vestibulum viverra orci id porta. Vestibulum lacinia nisl ut enim congue, quis bibendum eros suscipit. In sollicitudin nec ipsum quis scelerisque. Duis sit amet neque vestibulum, viverra orci id, dignissim leo.",
    priceRange: "Rs 50000 - RS 500000",
    city: "Jaipur",
    icon: "fa-utensils",
  },
  {
    imgsrc: "assets/Images/wedding-marraige-pandit.jpg",
    serviceType: "Pandit ",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc a nisl cursus, tincidunt leo eu, elementum tellus. Phasellus vehicula eros eu fringilla pellentesque. Sed commodo nulla ipsum, ut dictum turpis malesuada a. Integer eu semper velit, et sagittis elit. Integer magna magna, vestibulum ac elit tincidunt, ornare dictum lacus. Etiam at metus auctor, pulvinar neque id, fringilla nisl. In nunc felis, pharetra sed lacus id, vehicula aliquet tellus. Donec ultrices felis non sem pulvinar condimentum. Nulla vel fermentum sapien, ut aliquet lacus. Donec vestibulum viverra orci id porta. Vestibulum lacinia nisl ut enim congue, quis bibendum eros suscipit. In sollicitudin nec ipsum quis scelerisque. Duis sit amet neque vestibulum, viverra orci id, dignissim leo.",
    priceRange: "Rs 50000 - RS 300000",
    city: "Jaipur",
    icon: "fa-utensils",
  },

  {
    imgsrc: "assets/Images/wedding-dress.jpg",
    serviceType: "Wedding Dress ",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc a nisl cursus, tincidunt leo eu, elementum tellus. Phasellus vehicula eros eu fringilla pellentesque. Sed commodo nulla ipsum, ut dictum turpis malesuada a. Integer eu semper velit, et sagittis elit. Integer magna magna, vestibulum ac elit tincidunt, ornare dictum lacus. Etiam at metus auctor, pulvinar neque id, fringilla nisl. In nunc felis, pharetra sed lacus id, vehicula aliquet tellus. Donec ultrices felis non sem pulvinar condimentum. Nulla vel fermentum sapien, ut aliquet lacus. Donec vestibulum viverra orci id porta. Vestibulum lacinia nisl ut enim congue, quis bibendum eros suscipit. In sollicitudin nec ipsum quis scelerisque. Duis sit amet neque vestibulum, viverra orci id, dignissim leo.",
    priceRange: "Rs 50000 - RS 500000",
    city: "Jaipur",
    icon: "fa-utensils",
  },
  // Add more vendor data here
];

const truncateDescription = (description) => {
  if (description.length > 250) {
    return description.substring(0, 250) + "...";
  }
  return description;
};

const VendorListingPage = () => {
  let navigate = useNavigate();
  const [priceFilter, setPriceFilter] = useState("");
  const [locationFilter, setLocationFilter] = useState("");

  const handlePriceFilterChange = (event) => {
    setPriceFilter(event.target.value);
  };

  const handleLocationFilterChange = (event) => {
    setLocationFilter(event.target.value);
  };

  const filteredVendors = vendorData.filter((vendor) => {
    return (
      (priceFilter ? vendor.priceRange === priceFilter : true) &&
      (locationFilter ? vendor.city === locationFilter : true)
    );
  });

  return (
    <div>
      <section id="vendor-listing">
        <div className="container">
          <div className="row">
            <div className="col-12 d-lg-none">
              <div className="vendor-listing-page__filter">
                <div className="f1">
                  <h4>Filter by Price Range:</h4>
                  <select
                    value={priceFilter}
                    onChange={handlePriceFilterChange}
                    className="mb-5"
                  >
                    <option value="">All</option>
                    <option value="Rs 50000 - RS 100000">
                      Rs 50000 to RS 100000
                    </option>
                    <option value="Rs 50000 - RS 500000">
                      Rs 50000 to RS 500000
                    </option>
                    <option value="Rs 50000 - RS 200000">
                      Rs 50000 to RS 200000
                    </option>
                  </select>
                </div>

                <h4>Filter by Location:</h4>
                <select
                  value={locationFilter}
                  onChange={handleLocationFilterChange}
                  className="mb-5"
                >
                  <option value="">All</option>
                  <option value="Pune">Pune</option>
                  <option value="Jaipur">Jaipur</option>
                  <option value="Delhi">Delhi</option>
                </select>

                <h4>Filter by Location:</h4>
                <select
                  value={locationFilter}
                  onChange={handleLocationFilterChange}
                  className="mb-5"
                >
                  <option value="">All</option>
                  <option value="Pune">Pune</option>
                  <option value="Jaipur">Jaipur</option>
                  <option value="Delhi">Delhi</option>
                </select>
              </div>
            </div>
            <div className="col-12 col-lg-9" data-aos="zoom-out-right">
              <div className="vendor-listing-page__scrollable">
                {filteredVendors.map((vendor, index) => (
                  <div className="vendor-listing-page__card card" key={index}>
                    <div className="row g-0">
                      <div className="col-md-4">
                        <img
                          src={vendor.imgsrc}
                          className="img-fluid v-image"
                          alt={vendor.serviceType}
                        />
                      </div>
                      <div className="col-md-8 d-flex flex-column justify-content-between">
                        <div className="vendor-listing-page__card-body card-body">
                          <h5 className="vendor-listing-page__card-title card-title mt-3">
                            <i className={`fas ${vendor.icon}`}></i>{" "}
                            {vendor.serviceType}
                          </h5>
                          <p className="vendor-listing-page__card-text card-text">
                            {truncateDescription(vendor.description)}
                          </p>
                          <p className="vendor-listing-page__card-text card-text">
                            <i className="fas fa-tag"></i>{" "}
                            <strong>Price Range:</strong> {vendor.priceRange}
                          </p>
                          <p className="vendor-listing-page__card-text card-text">
                            <i className="fas fa-city"></i>{" "}
                            <strong>City:</strong> {vendor.city}
                          </p>
                          <div className="vendor-card-btn">
                            <Button
                              onClick={() => navigate("/vendor-info")}
                              text="Request pricing"
                              className="Details-btn"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div
              className="col-12 col-lg-3 d-none d-lg-block"
              data-aos="zoom-out-left"
            >
              <div className="vendor-listing-page__filter">
                <h4>Filter by Price Range:</h4>
                <select
                  value={priceFilter}
                  onChange={handlePriceFilterChange}
                  className="mb-5"
                >
                  <option value="">All</option>
                  <option value="Rs 50000 - RS 100000">
                    Rs 50000 to RS 100000
                  </option>
                  <option value="Rs 50000 - RS 500000">
                    Rs 50000 to RS 500000
                  </option>
                  <option value="Rs 50000 - RS 200000">
                    Rs 50000 to RS 200000
                  </option>
                </select>

                <h4>Filter by Location:</h4>
                <select
                  value={locationFilter}
                  onChange={handleLocationFilterChange}
                  className="mb-5"
                >
                  <option value="">All</option>
                  <option value="Pune">Pune</option>
                  <option value="Jaipur">Jaipur</option>
                  <option value="Delhi">Delhi</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default VendorListingPage;
