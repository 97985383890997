import React from "react";
import "./WeddingFeatures.css";
const WeddingFeatures = () => {
  return (
    <div>
      <section id="card-wed-fri">
        <div className="container">
          <div className="row align-items-center">
            <div className="centered-container">
              <h2
                className="section-heading"
                data-aos="zoom-in-down"
                data-aos-delay="100"
                data-aos-duration="900"
              >
                Experience the Magic
              </h2>
              <p
                className="section-para text-border"
                data-aos="zoom-in-up"
                data-aos-delay="150"
                data-aos-duration="900"
              >
                "Discover the enchanting moments that make our cosmic events
                unforgettable."
              </p>
            </div>
          </div>
          <div className="row align-items-center cards">
            <div
              className="col"
              data-aos="fade-right"
              data-aos-delay="300"
              data-aos-duration="400"
            >
              <div className="card">
                <div className="box">
                  <div className="content">
                    <img
                      src="assets/Images/featureCardImages/Budget.png"
                      alt="budget"
                    />
                    <h3>Budget</h3>
                    <p>Labore, totam velit? Iure nemo labore inventore?</p>
                    <a href="/feature">Read More</a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col"
              data-aos="fade-right"
              data-aos-delay="500"
              data-aos-duration="400"
            >
              <div className="card">
                <div className="box">
                  <div className="content">
                    <img
                      src="assets/Images/featureCardImages/guests.png"
                      alt="guest"
                    />
                    <h3>Guest List</h3>
                    <p>Labore, totam velit? Iure nemo labore inventore?</p>
                    <a href="/feature">Read More</a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col"
              data-aos="zoom-in-down"
              data-aos-delay="600"
              data-aos-duration="500"
            >
              <div className="card">
                <div className="box">
                  <div className="content">
                    <img
                      src="assets/Images/featureCardImages/sitting.png"
                      alt="seating chart"
                    />
                    <h3>Seating Chart</h3>
                    <p>Labore, totam velit? Iure nemo labore inventore?</p>
                    <a href="/feature">Read More</a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col"
              data-aos="zoom-in-down"
              data-aos-delay="600"
              data-aos-duration="500"
            >
              <div className="card">
                <div className="box">
                  <div className="content">
                    <img
                      src="assets/Images/featureCardImages/checklist.png"
                      alt="check list"
                    />
                    <h3>Check List</h3>
                    <p>Labore, totam velit? Iure nemo labore inventore?</p>
                    <a href="/feature">Read More</a>
                  </div>{" "}
                </div>
              </div>
            </div>
            <div
              className="col"
              data-aos="fade-left"
              data-aos-delay="500"
              data-aos-duration="400"
            >
              <div className="card">
                <div className="box">
                  <div className="content">
                    <img
                      src="assets/Images/featureCardImages/venderMng.png"
                      alt="Vender Manager"
                    />
                    <h3>Vendor Manger</h3>
                    <p>Labore, totam velit? Iure nemo labore inventore?</p>
                    <a href="/feature">Read More</a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col"
              data-aos="fade-left"
              data-aos-delay="300"
              data-aos-duration="400"
            >
              <div className="card">
                <div className="box">
                  <div className="content">
                    <img
                      src="assets/Images/featureCardImages/counselling.png"
                      alt="RVPS"
                    />
                    <h3>RSVP</h3>
                    <p>Labore, totam velit? Iure nemo labore inventore?</p>
                    <a href="/feature">Read More</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WeddingFeatures;
