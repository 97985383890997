import React, { useEffect ,useRef} from 'react';
import './FourNotFour.css';
import Button from '../Button/Button';
import { useNavigate,history } from 'react-router';
const FourNotFour = () => {
    let navigate = useNavigate();
    const timeoutRef = useRef(null);

    useEffect(() => {
        timeoutRef.current = setTimeout(() => {
            navigate(-1);
        }, 5000);

        return () => {
            clearTimeout(timeoutRef.current);
        };
    }, [navigate]);
    function randomNum() {
        return Math.floor(Math.random() * 9) + 1;
    }

    useEffect(() => {
        const time = 30;
        let i = 0;
        const selector3 = document.querySelector('.thirdDigit');
        const selector2 = document.querySelector('.secondDigit');
        const selector1 = document.querySelector('.firstDigit');

        if (selector3 && selector2 && selector1) {
            const loop3 = setInterval(() => {
                if (i > 40) {
                    clearInterval(loop3);
                    selector3.textContent = 4;
                } else {
                    selector3.textContent = randomNum();
                    i++;
                }
            }, time);

            const loop2 = setInterval(() => {
                if (i > 80) {
                    clearInterval(loop2);
                    selector2.textContent = 0;
                } else {
                    selector2.textContent = randomNum();
                    i++;
                }
            }, time);

            const loop1 = setInterval(() => {
                if (i > 100) {
                    clearInterval(loop1);
                    selector1.textContent = 4;
                } else {
                    selector1.textContent = randomNum();
                    i++;
                }
            }, time);

            return () => {
                clearInterval(loop1);
                clearInterval(loop2);
                clearInterval(loop3);
            };
        } else {
            console.error("One or more digit elements are not found in the DOM.");
        }
    }, []);

    return (
        <div>
            {/* Error Page */}
            <div className="error">
                <div className="container-floud">
                    <div className="col-xs-12 ground-color text-center">
                        <div className="container-error-404">
                            <div className="clip">
                                <div className="shadow">
                                    <span className="digit thirdDigit" />
                                </div>
                            </div>
                            <div className="clip">
                                <div className="shadow">
                                    <span className="digit secondDigit" />
                                </div>
                            </div>
                            <div className="clip">
                                <div className="shadow">
                                    <span className="digit firstDigit" />
                                </div>
                            </div>
                            <div className="msg">OH!<span className="triangle" /></div>
                        </div>
                        <h2 className="h1 ">Sorry! Page not found</h2>
                        <Button text='Go Back ' className="Goback-btn " onClick={()=> navigate(-1)} />
                    </div>
                </div>
            </div>
            {/* Error Page */}
        </div>
    );
};

export default FourNotFour;
