import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from "react-toastify";
import { toast_config } from "../../utils/constants";
const BASE_URL = process.env.REACT_APP_BASE_URL;



export const UserRegister = createAsyncThunk(
    "UserRegister", // Corrected action name to "UserRegister"
    async (arg, { rejectWithValue }) => {
        console.log('----- BASE_URL---------',  BASE_URL);
        try {
            const response = await axios.post(`${BASE_URL}/api/v1/User/register-user`, arg); 
            console.log('-----response---------', response);
            
            return response;
        } catch (error) {
            console.log('-----error---------', error);
            toast.error(error.response?.data?.message, toast_config);
            return rejectWithValue(error);
        }
    }
);

export const VendorRegister = createAsyncThunk(
    "VendorRegister", // Corrected action name to "VendorRegister"
    async (arg, { rejectWithValue }) => {
        console.log('-----arg---------', arg);
        try {
            const response = await axios.post(`${BASE_URL}/api/v1/User/register-vendor`, arg); // Adjusted endpoint for vendor registration
            console.log('-----response---------', response); // Check the entire response object
            const TOKEN = response.data.access_token;
            console.log('-----TOKEN---------', TOKEN); 
            if (TOKEN) {
                localStorage.setItem("token", response.data.data?.access_token);
                localStorage.setItem("user", JSON.stringify(response.data.data?.user)); 
                console.log('Token stored in local storage', TOKEN); // Log success message
            } else {
                console.error("No access token found in the response");
            }
            return response;
        } catch (error) {
            console.log('-----error---------', error);
            toast.error(error.response?.data?.message, toast_config);
            return rejectWithValue(error);
        }
    }
);

const RegisterSlice = createSlice({
    name: 'registerSlice',
    initialState: {
        userData: [],
        IsAdding: false,
        loading: false,
        error: null,
    },
    reducers: {
        IsAddingOpen: (state, action) => {
            state.IsAdding = true;
        },
        IsAddingClose: (state, action) => {
            state.IsAdding = false;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(UserRegister.pending, (state) => {
                state.loading = true;
            })
            .addCase(UserRegister.fulfilled, (state, action) => {
                state.loading = false;
                console.log(`-------UserRegister action---------`, action);
            })
            .addCase(UserRegister.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload.message; 
            })
            .addCase(VendorRegister.pending, (state) => {
                state.loading = true;
            })
            .addCase(VendorRegister.fulfilled, (state, action) => {
                state.loading = false;
                console.log(`-------VendorRegister action---------`, action);
            })
            .addCase(VendorRegister.rejected, (state, action) => { // Re-added this case for VendorRegister
                state.loading = false;
                state.error = action.payload.message; 
            });
    },
});

export const { IsAddingOpen, IsAddingClose } = RegisterSlice.actions;
export default RegisterSlice.reducer;
